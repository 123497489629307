<template>
  <div>
    <v-card flat tile>
      <v-overlay :value="overlay" absolute>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>

      <v-toolbar flat color="white">
        <v-toolbar-title>
          <v-icon class="mb-1">mdi-cogs</v-icon>
          GENERAR LOTE DE GUIAS
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-autocomplete
          v-model="aerolinea_id"
          small
          item-text="AEROLINEA_NOMBRE"
          item-value="AEROLINEA_ID"
          :items="listaAerolineas"
          @change="cargarLotes()"
          clearable
          label="Aerolínea"
          class="mt-5"
        >
        </v-autocomplete>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-toolbar flat color="white">
        <v-btn small color="info" class="mx-2" @click="abrirDialogLote"
          ><v-icon left>mdi-plus</v-icon>Crear lote</v-btn
        >
        <v-btn small color="info" class="mx-2" @click="borrarLote"
          ><v-icon left>mdi-delete</v-icon>Borrar</v-btn
        >
        <v-btn small color="info" class="mx-2" @click="aprobarLote"
          ><v-icon left>mdi-check</v-icon>Aprobar</v-btn
        >
        <!-- <v-btn small color="info" class="mx-2"
          ><v-icon left>mdi-cogs</v-icon>Generar Guias</v-btn
        > -->
      </v-toolbar>

      <v-data-table
        id="lotesGuias"
        :headers="headerLote"
        :options.sync="options"
        :items="lotes"
        :itemsPerPage="itemsPerPage"
        :server-items-length="totalItems"
        show-expand
        :single-expand="true"
        @item-expanded="cargarGuiasLote"
        :show-select="true"
        single-select
        v-model="loteSelected"
        class="elevation-1"
        item-key="GEN_GUIA_ID"
        hide-default-footer
      >
        <template v-slot:item.APROBADO="{ item }">
          {{ item.APROBADO == 0 ? "SIN APROBAR" : "APROBADO" }}
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td colspan="12">
            <v-row>
              <v-col cols="12" class="my-2">
                <v-alert type="success" dense class="mb-0 py-1">
                  Guias generadas en el lote
                </v-alert>

                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">Num guia</th>
                        <th class="text-center">Aerolinea</th>
                        <th class="text-center">Estado</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(guia, index) in guiasLote" :key="index">
                        <td>{{ guia.AWB_NUM }}</td>
                        <td>{{ guia.AEROLINEA_NOMBRE }}</td>
                        <td>{{ guia.ESTADO }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </td>
        </template>

        <template v-slot:footer>
          <v-pagination
            class="mt-10"
            v-model="currentPage"
            :length="pageCount"
            @input="handlePageChange"
            total-visible="10"
          ></v-pagination>
        </template>
      </v-data-table>
    </v-card>
    <!-- dialog ingresar lote -->
    <v-dialog v-model="dialogIngresarLote" max-width="600px">
      <v-form ref="formIngresarLote">
        <v-card class="pb-2">
          <v-toolbar height="40" color="blue" flat dark>
            <v-toolbar-title>Ingresar Lote</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-btn text @click="dialogIngresarLote = false"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-toolbar>
          <v-card-text class="mt-4">
            <v-row>
              <v-col class="pt-0 pb-0 my-0" cols="12">
                <v-text-field
                  class="my-2"
                  v-model="datosLote.FECHA"
                  label="Fecha"
                  type="date"
                  :rules="requiredRule"
                ></v-text-field>
              </v-col>
              <v-col class="pt-0 pb-0 my-0" cols="12">
                <v-text-field
                  class="my-0 py-2"
                  v-model="datosLote.GEN_GUIA_NUM"
                  label="Numero de lote"
                  :rules="requiredRule"
                ></v-text-field>
              </v-col>
              <v-col class="pt-0 pb-0 my-0" cols="12">
                <v-select
                  class="my-0 py-2"
                  :items="listaAerolineas2"
                  item-value="AEROLINEA_ID"
                  item-text="AEROLINEA_NOMBRE"
                  v-model="datosLote.AEROLINEA_ID"
                  label="Aerolínea"
                  :rules="requiredRule"
                ></v-select>
              </v-col>
              <v-col class="pt-0 pb-0 my-0" cols="12">
                <v-select
                  class="my-0 py-2"
                  :items="tiposGuia"
                  item-value="TIPO_GUIA_ID"
                  item-text="TIPO_GUIA"
                  v-model="datosLote.TIPO_GUIA_ID"
                  label="Tipo de guia"
                  :rules="requiredRule"
                ></v-select>
              </v-col>
              <v-col class="pt-0 pb-0 my-0" cols="12">
                <v-text-field
                  class="my-0 py-2"
                  v-model="datosLote.PREFIJO"
                  label="Prefijo"
                  :rules="requiredRule"
                ></v-text-field>
              </v-col>
              <v-col class="pt-0 pb-0 my-0" cols="12">
                <v-text-field
                  class="my-0 py-2"
                  v-model="datosLote.DESDE"
                  label="Desde"
                  :rules="mod7RuleDesde"
                ></v-text-field>
              </v-col>
              <v-col class="pt-0 pb-0 my-0" cols="12">
                <v-text-field
                  class="my-0 py-2"
                  v-model="datosLote.HASTA"
                  label="Hasta"
                  :rules="mod7RuleHasta"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mb-1">
            <v-btn
              class="mx-4 white--text mb-1"
              small
              color="green"
              @click="guardarLote"
              ><v-icon small left>mdi-content-save</v-icon>Guardar</v-btn
            >
            <v-btn
              class="mx-4 white--text mb-1"
              small
              text
              color="blue"
              @click="dialogIngresarLote = false"
              ><v-icon small left>mdi-cancel</v-icon>Cancelar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<style>
#lotesGuias td,
.v-input--selection-controls .v-radio > .v-label {
  font-size: 0.65rem !important;
}
</style>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import moment from "moment";
import Vue from "vue";

export default {
  name: "GenerarLoteGuias",
  components: {},
  props: {},

  data: () => ({
    headerLote: [
      { text: "LOTE#", value: "GEN_GUIA_ID" },
      { text: "Fecha", value: "FECHA" },
      { text: "Tipo de guias", value: "TIPO_GUIA_ID" },
      { text: "Aerolínea", value: "AEROLINEA_NOMBRE" },
      { text: "Prefijo", value: "PREFIJO" },
      { text: "Desde", value: "DESDE" },
      { text: "Hasta", value: "HASTA" },
      { text: "Total de guias", value: "GUAS_GENERADAS" },
      { text: "Estado", value: "APROBADO" },
      { text: "Aprobado por", value: "APROBADO_POR" },
      { text: "Modificado por", value: "INGRESADO_POR" },
    ],
    headerGuiasLote: [
      { text: "Guia", value: "AWB_NUM" },
      { text: "Aerolinea", value: "AEROLINEA_NOMBRE" },
      { text: "Estado", value: "ESTADO" },
      { text: "Fecha modificación", value: "FECHA_MODIFICADO" },
    ],
    overlay: false,
    itemsPerPage: 50,
    totalItems: 0,
    currentPage: 1,
    pageCount: 0,
    datosLote: {
      GEN_GUIA_ID: "",
      GEN_GUIA_NUM: "",
      AEROLINEA_ID: "",
      FECHA: "",
      DESDE: "",
      HASTA: "",
      TIPO_GUIA_ID: "",
      ING_USUARIO_ID: "",
      MOD_USUARIO_ID: "",
      APROBADO: false,
      APROBADO_USUARIO_ID: "",
      APROBADO_FECHA: "",
      GENERADO_FECHA: "",
      PREFIJO: "",
      GUAS_GENERADAS: "",
      ING_FECHA: "",
      MOD_FECHA: "",
    },
    listaAerolineas: [],
    listaAerolineas2: [],
    tiposGuia: [
      { TIPO_GUIA_ID: "1", TIPO_GUIA: "NEUTRA" },
      { TIPO_GUIA_ID: "2", TIPO_GUIA: "GUIA MANUAL" },
    ],
    aerolinea_id: "",
    estado_id: "",
    lotes: [],
    loteSelected: [],
    guiasLote: [],
    options: {},
    dialogIngresarLote: false,
    requiredRule: [
      (v) =>
        (v != "" && v != null && v != undefined) || "El campo es requerido",
    ],
  }),
  computed: {
    ...mapState("master", ["loadingTable", "user", "urlApi"]),
    mod7RuleDesde(val) {
      console.log(val);
      //if (this.datosLote.DESDE.length > 0) {
      let s = this.datosLote.DESDE.substring(
        0,
        this.datosLote.DESDE.length - 1
      );
      let n = parseInt(s);
      let mod = n % 7;
      //console.log(mod);
      if (this.datosLote.DESDE != s + mod) {
        return [false || "El número es invalido"];
      }
      //}
      return [];
    },

    mod7RuleHasta(val) {
      console.log(val);
      //if (this.datosLote.DESDE.length > 0) {
      let s = this.datosLote.HASTA.substring(
        0,
        this.datosLote.HASTA.length - 1
      );
      let n = parseInt(s);
      let mod = n % 7;
      //console.log(mod);
      if (this.datosLote.HASTA != s + mod) {
        return [false || "El número es invalido"];
      }
      //}
      return [];
    },
  },
  methods: {
    ...mapActions("master", ["requestApi", "alertNotification"]),
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setTitleToolbar",
      "setLoadingTable",
    ]),
    cargarLotes() {
      this.setLoadingTable(true);
      this.setUrl("api/lotes-guias");
      this.requestApi({
        method: "GET",
        data: {
          pageCount: this.pageCount,
          itemsPerPage: this.itemsPerPage,
          page: this.currentPage,
          sortBy: this.options.sortBy[0],
          sortDesc: this.options.sortDesc[0],
          aerolinea_id: this.aerolinea_id,
          estado_id: this.estado_id,
        },
      })
        .then((res) => {
          this.lotes = res.data.lista;
          this.pageCount = res.data.pageCount;
          this.totalItems = res.data.totalItems;
          this.listaAerolineas = res.data.aerolineas;
          this.listaAerolineas2 = res.data.aerolineas;
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    cargarGuiasLote({ item }) {
      this.setLoadingTable(true);
      this.setUrl("api/guias-lote");
      this.requestApi({
        method: "GET",
        data: {
          //pageCount: this.pageCount,
          itemsPerPage: this.itemsPerPage,
          page: 1,
          sortBy: this.options.sortBy[0],
          sortDesc: this.options.sortDesc[0],
          //aerolinea_id: this.aerolinea_id,
          estado_id: this.estado_id,
          gen_guia_id: item.GEN_GUIA_ID,
        },
      })
        .then((res) => {
          this.guiasLote = res.data.guiasLote;
          //this.pageCount = res.data.pageCount;
          //this.totalItems = res.data.totalItems;
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    guardarLote() {
      if (!this.$refs.formIngresarLote.validate()) {
        return false;
      }
      this.setLoadingTable(true);
      this.setUrl("api/guardar-lote");

      this.requestApi({
        method: "POST",
        data: {
          datosLote: this.datosLote,
        },
      })
        .then((res) => {
          this.dialogIngresarLote = false;
          this.cargarLotes;
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    borrarLote() {
      //this.setLoadingTable(true);
      this.setUrl("api/borrar-lote");

      Vue.swal({
        html: "Está seguro de eliminar este lote?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.requestApi({
            method: "POST",
            data: {
              genGuiaId: this.loteSelected[0].GEN_GUIA_ID,
            },
          })
            .then((res) => {
              //this.dialogIngresarLote = false;
            })
            .catch(() => {})
            .then(() => {
              //this.setLoadingTable(false);
            });
        }
      });
    },
    aprobarLote() {
      this.setLoadingTable(true);
      this.setUrl("api/aprobar-lote");

      this.requestApi({
        method: "POST",
        data: {
          genGuiaId: this.loteSelected[0].GEN_GUIA_ID,
        },
      })
        .then((res) => {
          this.cargarLotes();
          this.loteSelected = [];
          this.dialogIngresarLote = false;
        })
        .catch(() => {})
        .then(() => {
          this.cargarLotes();
          this.loteSelected = [];
          this.setLoadingTable(false);
        });
    },

    abrirDialogLote() {
      this.datosLote = {
        GEN_GUIA_ID: "",
        GEN_GUIA_NUM: "",
        AEROLINEA_ID: "",
        FECHA: "",
        DESDE: "",
        HASTA: "",
        TIPO_GUIA_ID: "",
        ING_USUARIO_ID: "",
        MOD_USUARIO_ID: "",
        APROBADO: false,
        APROBADO_USUARIO_ID: "",
        APROBADO_FECHA: "",
        GENERADO_FECHA: "",
        PREFIJO: "",
        GUAS_GENERADAS: "",
        ING_FECHA: "",
        MOD_FECHA: "",
      };
      this.dialogIngresarLote = true;
    },
    handlePageChange() {
      this.cargarLotes();
    },
  },

  mounted() {
    //document.title = "Inventario de guias";
    //this.setTitleToolbar("Invetnario de guias");
    this.cargarLotes();
    console.log(this.user.urlApi);
    console.log(this.user.user_login_id);
  },
  watch: {
    "datosLote.AEROLINEA_ID": function (val) {
      for (let i = 0; i < this.listaAerolineas.length; i++) {
        //console.log(this.datosLote.AEROLINEA_ID);
        if (
          this.listaAerolineas[i].AEROLINEA_ID == this.datosLote.AEROLINEA_ID
        ) {
          //console.log(this.listaAerolineas[i].AEROLINEA_ID);
          this.datosLote.PREFIJO = this.listaAerolineas[i].AERO_PRE_GUIA;
        }
      }
    },
  },
};
</script>
